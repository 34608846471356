import React from 'react';
import {
  ComponentPreview,
  ComponentWithLabel,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Radio, RadioGroup } from 'hudl-uniform-ui-components';
import SectionSize from '../partials/_SectionSize';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatform from '../partials/_SectionPlatform';
import pageHeroData from '../../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      radioValue: '',
    };
  }

  onRadioChange = value => this.setState({ radioValue: value });

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Radio"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Forms" tierThree="Radio" />

        <PageNavigation>
          <PageNavigationLink>Size</PageNavigationLink>
          <PageNavigationLink>State</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <SectionSize text="Radio" />

        <Section title="State">
          <Paragraph>
            A radio’s state depends on the interaction itself or a submission
            error.
          </Paragraph>
          <ComponentPreview name="RadioState">
            <ComponentWithLabel>
              <RadioGroup
                header="Recipients"
                name="testRadio"
                onChange={this.onRadioChange.bind(this)}
                valueChecked={this.state.radioValue}>
                <Radio label="Coaches" value="coaches" name="testRadio" />
                <Radio label="Athletes" value="athletes" name="testRadio" />
                <Radio
                  label="Opposing Team"
                  value="opposingTeam"
                  name="testRadio"
                  disabled
                />
              </RadioGroup>
            </ComponentWithLabel>
          </ComponentPreview>
          <Paragraph>
            The actual <em>focus</em> state is sorted for you, so anyone
            navigating via keyboard or screen reader is set. A disabled radio
            prevents the user from interacting with a field until certain
            conditions are met. It’s a good way to prevent invalid submissions,
            but should be used sparingly.
          </Paragraph>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Uniform radio buttons may be used for both Apple and Android
            devices. Keep a close eye on the intent of the radio and never use
            it outside of a form.
          </Paragraph>

          <Paragraph>
            If the requirement is to switch between different views, use a{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/segmented-controls/">
              segmented control
            </Link>{' '}
            instead. When asking the user to enable or disable a function,
            feature or setting, use a{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/">
              switch
            </Link>
            .
          </Paragraph>
        </Section>
        <SectionUsage />

        <Section title="Microcopy">
          <SectionSubhead>Labels</SectionSubhead>
          <Paragraph>
            A label should clearly and concisely describe the field’s purpose
            with no more than a few words.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="make the label a complete sentence."
              img="radio-labels-dont"
            />
            <DontDoItem
              type="do"
              text="keep it short and sweet."
              img="radio-labels-do"
            />
          </DontDo>
          <Paragraph>
            Check{' '}
            <Link href="/words/content-elements/labels-non-label-text/">
              UI label guidelines
            </Link>{' '}
            for more detail on case and punctuation.
          </Paragraph>
          <Paragraph>
            For required fields,{' '}
            <strong>place an asterisk at the beginning of the label.</strong>{' '}
            There’s no need to spell out required and extend the length of that
            label.
          </Paragraph>
        </Section>

        <SectionPlatform />
      </PageWithSubNav>
    );
  }
}
